import { checkPropTypes } from "prop-types";
import * as React from "react";
import * as ReactDOM from "react-dom";
import * as uuid from "uuid";
import logo from "./logo.svg";

const apiUrl = location.host === "localhost:5000"
    ? "http://localhost:5001/karaokist/us-central1/agenda"
    : "https://us-central1-karaokist.cloudfunctions.net/agenda";

const session = sessionStorage.session = window.sessionStorage.session || uuid.v4();

const classNames = (names: { [name: string]: boolean }, ...rest: string[]) =>
    [...rest, ...Object.entries(names).filter(([k, v]) => v).map(([k, v]) => k)].join(" ");

const AgendaView = (agenda) => (
    null
);

class App extends React.Component<{}, { response, error, loading, requesting }> {
    private readonly loadingEngine = this.createLoadingEngine();
    private timeout = null;

    constructor(props) {
        super(props);

        this.state = {
            response: null,
            error: null,
            loading: false,
            requesting: false,
        };

        this.loadingEngine.next();
    }

    public componentDidMount() {
        this.refreshLoop();
    }

    public componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    private onRedirect(e: React.FormEvent) {
        const form = e.target as HTMLFormElement;
        const formData = new FormData(form);

        e.preventDefault();

        const { response: { landing: { redirectPrefix } } } = this.state;
        window.location = redirectPrefix + formData.get("suffix");
    }

    public render() {
        const { error, response, requesting } = this.state;
        const { landing = null, agenda = null } = response || {};

        return (
            <React.Fragment>
                <header className="titleBar">
                    <img src={logo} className="title" alt="Karaokist" />
                    {agenda && <h2 className="subtitle">{agenda.subtitle}</h2>}
                </header>
                {landing && (
                    <React.Fragment>
                        <section className="contents">
                            <form className="redirectForm" onSubmit={(e) => this.onRedirect(e)}>
                                <span className="displayPrefix">{landing.displayPrefix}</span>
                                <input className="suffixInput" type="text" name="suffix" autoFocus autoCapitalize="none" />
                                <input className="redirectSubmit" type="submit" value="Go" />
                            </form>
                            {landing.wrongRoom && <p className="roomNotFound">Not found!</p>}
                        </section>
                    </React.Fragment>
                )}
                {agenda && (
                    <React.Fragment>
                        <section className="contents">
                            {agenda.message && <p className="message">{agenda.message}</p>}
                            <ul className="agenda">
                                {agenda.rows.map(({
                                    index,
                                    songTitle,
                                    karaokistName,
                                    mine,
                                    pending,
                                }, i) => (
                                        <li key={i} className={classNames({
                                            mine,
                                            pending,
                                        }, "agendaRow")}>
                                            <span className={classNames({
                                                mine,
                                                pending,
                                            }, "index")}>{index || "?"}</span>
                                            <span className="songTitle">{songTitle}</span>
                                            <span className="karaokistName">{karaokistName}</span>
                                        </li>
                                    ),
                                )}
                            </ul>
                            {agenda.canRequest
                                && <form
                                    className="addRequestForm"
                                    onSubmit={(e) => this.onSubmit(e)} >
                                    <span className="requestFormIndex">+</span>
                                    <input
                                        name="songTitle"
                                        className="songTitleInput"
                                        required
                                        placeholder="Song title - Artist"
                                        disabled={this.state.requesting}
                                    />
                                    <input
                                        name="karaokistName"
                                        className="karaokistNameInput"
                                        required
                                        placeholder="Your name"
                                        disabled={this.state.requesting}
                                    />
                                    {
                                        requesting
                                            ? <div className="submitPending" />
                                            : <input
                                                type="submit"
                                                className="submit"
                                                disabled={this.state.requesting}
                                            />
                                    }
                                </form>
                            }
                        </section>
                    </React.Fragment>
                )}
                {error && <p>{error.message}</p>}
            </React.Fragment>
        );
    }

    private async onSubmit(e: React.FormEvent) {
        const form = e.target as HTMLFormElement;
        const formData = new FormData(form);

        e.preventDefault();

        if (this.state.requesting) {
            return;
        }

        this.setState({ requesting: true });
        try {
            await this.loadingEngine.next(api("add", formData as Iterable<[string, string]>));
        } finally {
            this.setState({ requesting: false });
        }

        form.reset();
    }

    private async* createLoadingEngine() {
        while (true) {
            const responsePromise = yield;
            this.setState({ loading: true });
            try {
                const response = await responsePromise;
                this.setState({ response, error: null });
            } catch (error) {
                this.setState({ error });
            } finally {
                this.setState({ loading: false });
            }
        }
    }

    private async refreshLoop() {
        await this.loadingEngine.next(api("list"));
        this.timeout = setTimeout(() => this.refreshLoop(), 30000);
    }

}

async function api(method: string, data: Iterable<[string, string]> = []) {
    const body = new URLSearchParams([...data]);
    body.append("location", window.location.href);
    body.append("session", session);
    const response = await fetch(`${apiUrl}/${method}`, {
        body,
        method: "POST",
    });
    if (!response.ok) {
        const { message } = await response.json();
        throw new Error(message);
    }
    return await response.json();
}

ReactDOM.render(<App />, document.getElementById("root"));
